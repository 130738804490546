body.is-alerts {
  @apply text-2xs;

  .portfolio-error-text {
    @apply text-red-500 dark:text-red-400;
  }

  .portfolio-error-input {
    @apply border border-red-500 dark:border-red-400;
  }

  .styled-table-new td {
    @apply py-1 leading-none;
  }
}

.alerts_page-info {
  @apply mb-6 w-full bg-secondary py-2.5 text-center text-2xs leading-4 text-muted;
}

.alerts_ticker-input {
  width: 100px;
}
.alerts_price-input {
  width: 120px;
}
