.is-elite-page {
  .fv-container {
    @apply px-3;
  }
}

/* Top section noise */
.elite-page_grain {
  background: url('/elite/noise_small.png') center;
  background-size: 90px;
}

/* Top highlight gradients */
.elite-page_highlight {
  @apply absolute left-1/2 h-110 w-72 -translate-x-1/2 blur-2xl md:w-110 md:blur-[50px] lg:h-175 lg:blur-3xl;
}

.elite-page_highlight_ellipse-1 {
  @apply absolute -top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-violet-600/80 to-violet-900/65;
  border-radius: 100%;
}

.elite-page_highlight_ellipse-2 {
  @apply absolute -top-0 bottom-[78%] left-[17.5%] right-[17.5%] bg-violet-400/70;
  border-radius: 100%;
}

.elite-page_highlight_ellipse-3 {
  @apply absolute bottom-[54%] left-[38%] right-[38%] top-0 bg-violet-300/30;
  border-radius: 100%;
}

/* Bordered image wrapper */
.elite-page_image {
  --gradient-x: 50%;
  --gradient-y: 100%;
  @apply relative border-2 border-transparent bg-origin-border md:border-[3px];
  @apply [--gradient-bg:theme(colors.gray.200)] dark:[--gradient-bg:theme(backgroundColor.secondary)];
  background-image: linear-gradient(to bottom, theme(backgroundColor.primary) 0%, theme(backgroundColor.primary) 100%),
    radial-gradient(40% 50% at var(--gradient-x) var(--gradient-y), currentColor 0%, var(--gradient-bg) 100%);
  background-clip: content-box, border-box;

  &.is-inset-shadow:after {
    @apply absolute inset-0 block;
    box-shadow: inset 0 -3px 15px 8px theme(colors.gray.900 / 50%);
  }

  .dark &,
  &.is-shadow {
    @apply shadow-[0_5px_9px_2px_theme(colors.gray.900/50%)];
  }
}

.elite-page_divider {
  background-image: radial-gradient(50% 75% at 50% 2%, currentColor 2%, transparent 95%);

  &.is-shadow {
    box-shadow:
      0 -1px 1px #191b23,
      0 -2px 2px #191b23,
      0 -8px 8px #191b23;
  }
}

/* Features table responsive borders logic */
.feature-table_cell {
  @apply border-l-[3px] border-gray-200 dark:border-gray-600 md:last:border-r-[3px] md:[&:nth-child(3)]:border-l;

  .feature-table_row:first-child & {
    @apply rounded-tl-xl border-t-[3px] md:last:rounded-tr-xl md:[&:nth-child(3)]:rounded-l-none;
  }

  .feature-table_row:last-child & {
    @apply rounded-bl-xl border-b-[3px] md:last:rounded-br-xl md:[&:nth-child(3)]:rounded-l-none;
  }
}

/* Features table checkmark tick glow */
.feature-table_cell_icon-glow {
  filter: drop-shadow(0px 0px 4px theme(colors.emerald.300 / 50%));
}

/* FAQ open/close transitions */
.faq-disclosure {
  summary::-webkit-details-marker {
    @apply hidden;
  }
}

.faq-content {
  @apply max-h-0 overflow-hidden transition-[max-height] duration-300 ease-out;

  .faq-disclosure[open] + & {
    @apply max-h-100;
    max-height: var(--faq-max-height);
  }
}

/* Apply marquee fallback, which translates back and forth */
@media (prefers-reduced-motion: no-preference) {
  .elite-companies-marquee {
    animation: marqueeFallback 35s ease-in-out infinite alternate;

    &.is-loaded {
      animation: none;
    }
  }
}

@keyframes marqueeFallback {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% + 100vw));
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 24px));
  }
}
