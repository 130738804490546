.news-content {
  h2,
  h3 {
    @apply mb-4 mt-6 text-left text-2xl text-muted font-bold first:mt-0;
  }

  p {
    @apply mt-3 first:mt-0;
  }

  img {
    @apply mx-auto mb-0 mt-4 block max-w-full first:mt-0;
  }
}

.news-publish-info {
  @apply mt-4 flex h-7 items-center justify-between text-2xs leading-4 text-muted-2;
}

.ticker-badge {
  @apply max-w-40 rounded-md border border-secondary px-2 py-1.5 hover:border-gray-300 hover:bg-secondary dark:hover:border-gray-400;
}

.ticker-badge_name {
  @apply mr-1.5 text-sm text-link font-semibold;
}

.ticker-badge_company {
  @apply truncate text-2xs leading-4 text-muted;
}

.ticker-badge_change {
  @apply rounded-[3px] text-3xs leading-4;
}
