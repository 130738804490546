@import './variables.css';
@import './finviz-important-old.css';
@import '../modal-ad.css';
@import '../map.css';

@import './components/index.css';

@import './header.css';
@import './screener/index.css';
@import './futures.css';
@import './forex.css';
@import './crypto.css';
@import './futures-forex-crypto.css';
@import './quote/index.css';
@import './homepage/index.css';
@import './insidertrading/index.css';
@import './news/index.css';
@import './portfolio.css';
@import './quote/index.css';
@import './screener/index.css';
@import './groups.css';
@import './save-to-portfolio.css';
@import './calendar.css';
@import './search.css';
@import './alerts.css';
@import './myaccount.css';
@import './account.css';
@import './screener-presets.css';
@import './feature-preview.css';
@import './notification.css';
@import './help.css';
@import './privacy.css';
@import './elite.css';
@import './news-content.css';

:root {
  /**
   * Temp fix to override old css on redesigned pages. Cannot be defined in variables.css,
   because it is imported in compatibility.css
   **/
  --font-family: theme(fontFamily.sans);
  --text-color: var(--text-link);
  --link-color: var(--text-link);
  --link-hover-color: var(--text-link);
  --link-blue: var(--text-link);
  --link-blue-hover: var(--text-link);
}

html {
  @apply bg-white font-sans;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  &.dark {
    @apply bg-gray-800;

    body {
      @apply text-default;
      background-color: transparent;
    }
  }

  body {
    /* TODO: leave `text-default` only here after css import order is corrected  */
    @apply text-default;
    min-width: 1000px;
  }
}

body {
  @apply flex min-h-screen flex-col;

  .content {
    @apply grow;
  }

  img {
    @apply inline-block max-w-none;
  }
}

a.tab-link-nw,
a.nn-tab-link,
a.tab-link:link,
a.tab-link:visited {
  @apply text-link hover:underline;
}

a.nn-tab-link:visited {
  @apply text-muted-2;
}
